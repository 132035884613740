import React from 'react';
import ReactDOM from 'react-dom';
import reportWebVitals from './reportWebVitals';
import 'antd/dist/antd.css';
import '../src/assets/css/framework.css';
import '../src/assets/css/styles.css';
import '../src/assets/css/dark-theme.css';
import '@progress/kendo-theme-default/dist/all.css';
import IdleCmp from './auth/idle.component';
import { getConfig } from './auth/config';
import { Auth0Provider } from '@auth0/auth0-react';

const config = getConfig();
const providerConfig = {
  domain: config.domain,
  clientId: config.clientId,
  authorizationParams:{
    redirect_uri: process.env.REACT_APP_REDIRECT_URI,
    ...(config.audience ? { audience: config.audience } : null)
  },
};
ReactDOM.render(
  <Auth0Provider {...providerConfig}>
  <React.StrictMode>
    <IdleCmp />
  </React.StrictMode>
    </Auth0Provider>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals(console.log);
