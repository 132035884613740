import { Layout } from 'antd';
import { useEffect } from 'react'
import AppContent from './content';
import AppFooter from './footer';
import AppHeader from './header';
import CallbackPage from '../auth/callback.component';
import OnBoarding from './onboard.component';
import { userManager } from '../auth';
import { connect } from "react-redux";
import { useAuth0 } from "@auth0/auth0-react";
import { profileSuccess,setToken } from '../reducers/authReducer';
import getmemeberInfo from '../reducers/configReduser';
const AppLayout =(props)=> {
  const { isAuthenticated, loginWithRedirect, user, getAccessTokenSilently} = useAuth0()

    const callApi = async () => {
      try {  
        const token = await getAccessTokenSilently();  
        props.acctoken(token)  
      } catch (error) {  
      }
      };  
   useEffect(() => {    
      if (!isAuthenticated) {  
        loginWithRedirect();  
      } else {  
        props.updateProfile(user)  
        callApi()  
      }
      }, [])
   
  
    const redirect = () => {
      userManager.removeUser()
      window.open(process.env.REACT_APP_ADMIN_URL, "_self")
  }


if(!isAuthenticated || !props?.user?.profile || !props?.user?.deviceToken) {
   <div className="loader">Loading .....</div>

}else if ((!props.user) && window.location.pathname.includes('callback')) {
  return <CallbackPage />
}  else if (props?.user?.profile && !props?.userProfile) {

  return <OnBoarding />

} else if (props?.userProfile && props?.userProfile?.role === 'Admin') {
 redirect()
} else if (props?.twoFA?.loading) {

  return <div className="loader">Loading .....</div>

} if (props?.history && !props?.userProfile) {

  return <AppHeader />

}
    return(<>
           <Layout>
           <AppHeader />
            <AppContent />
            <AppFooter />
            </Layout>
    </>)
}
const connectStateToProps = ({ user, userConfig,auth }) => {
  return { 
    user : auth?.user,
    userProfile: userConfig.userProfileInfo
   }
}
const connectDispatchToProps = dispatch => {
  return {
    updateProfile: (info) => { dispatch(profileSuccess(info)) },
    acctoken: (data) => { dispatch(setToken(data)) },
    getmemeberInfoa: (useremail) => {
      dispatch(getmemeberInfo(useremail));
    },
    dispatch
  }}
export default connect(connectStateToProps, connectDispatchToProps)(AppLayout);
